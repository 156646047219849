section.media{
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
        @include title(210px);
    }
    .splide-container{
        display: flex;
        justify-content: center;
        align-items: center;
        .frame-container{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .slide-container{
            margin: auto;
            width: 100%;
            height: auto;
            padding: 0 60px;
        }
    }
}

@media (max-width: 576px){
    section.media{
        .splide-container{
            .frame-container{
                iframe{
                    width: 300px;
                }
            }
        }
    }
}