.newsletter-container{
    left: 20px;
    bottom: 20px;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    .btn-close{
        position: absolute;
        top: 5px;
        right: 5px;
    }
    .title{
        font-size: large;
        font-weight: bold;
    }
    .body{
        font-size: medium;
        max-width: 80%;
        margin: 10px 0 ;
    }
    .input-field{
        width: 100%;
        display: flex;
        justify-content: center;
        input{
            margin-right: 10px;
        }
    }
    .tiny-letter{
        margin-top: 15px;
        font-size: x-small;
    }
}
