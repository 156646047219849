section.articles{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    margin-bottom: 100px;
    .title{
        @include title(175px);
    }
    .container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        max-width: 1300px;
        .cardPost{
            margin: 0 20px;
            width: 300px;
            display: flex;
            border-radius: 15px!important;
            display: flex;
            flex-direction: column;
            box-shadow: 0 0 30px silver;
            .header{
                background-color: $colorPrincipal;
                padding: 20px;
                color: white;
                border-top-left-radius: 15px!important;
                border-top-right-radius: 15px!important;
                i{
                    margin-right: 10px;
                }
            }
            .text{
                height: 190px;
                padding: 20px;
                h5{
                    font-size: large;
                }
                h6{
                    font-size: medium;
                    color: gray;
                }
            }
            a{
                text-decoration: none;
                button{
                    margin: auto;
                    
                }
            }
        }
    }
    .more{
        margin-top: 20px;
        text-decoration: none;
        color: $colorPrincipal;
    }
}

@media (max-width: 1100px){
    section.articles{
        .container{
            flex-direction: column;
            padding: 10px;
            align-items: center;
            .cardPost{
                width: 700px;
                margin: 30px auto;
                .text{
                    height: 100px;
                }
            }
        }
    }
}


@media (max-width: 768px){
    section.articles{
        .container{
            
            .cardPost{
                width: 95%;
                margin: 30px auto;
                .text{
                    height: auto;
                }
            }
        }
    }
}