section.aboutme{
    display: flex;
    width: 100vw;
    flex-wrap: wrap;
    .side-about{
        width: 25%;
        background-image: url('./sections/aboutme.png');
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        .name{
            text-align: center;
            font-size: xx-large;
            font-family: $fontPrincipal;
            margin-bottom:30px;
            color: white;
            font-weight: bold;
        }
    }
    .content{
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 100px 0;
        height: calc( 100vh - 60px) ;
        .title{
            @include title(175px);
        }
        .paragraph{
            width: 80%;
        }
    }
}


@media (max-width: 576px){
    section.aboutme{
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: 100px;
        .side-about{
            width: 100%;
            height: 30vh;
            background-position:top;
            display: none;
        }
        .content{
            background-image: url('./sections/aboutme-fullblue.png');
            background-position-x: center;
            background-size: cover;
            padding: 30px 0;
            width: 100%;
            height: calc( 70% - 40px);
            justify-content: space-around;
            .title{
                @include title(175px);
                h3{
                    color: white;
                }
            }
            .item-desc{
                p{
                    font-size: x-large;
                    width: 90%!important;
                }
            }
            .buttons{
                width: 100%;
                margin-top: 10vh;
            }
            .paragraph{
                max-height: 50vh;
                overflow-y: scroll;
                text-align: center;
                margin-bottom: 40px;
                p{
                    color: white;
                }
            }
        }
    }
}