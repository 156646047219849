section.webinar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px 0 ;
    padding: 0 15%;
    .splide-container{
        width: 100%;
        .img-container{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            img{
                width: 50%;
            }
        }

    }
    .title{
        @include title(340px);
    }
    .container{
        display: flex;
        justify-content: center;
        .item-container{
            margin: 0 20px;
            width: 175px;
            height: 175px;
            display: flex;
            justify-content: center;
            align-items: center;
            
            .item{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 75px;
                height: 75px;
                border-radius: 50%;
                background-color: $colorPrincipal;
                color: white;
                transition: all .3s ease-in-out;
                box-shadow: 0 0 30px gray;
                cursor: pointer;
                .date{
                    font-weight: bold;
                }
                .link, .title, .date{
                    height: 0;
                    opacity: 0;
                    pointer-events: none;
                }
                .title{
                    margin: 0;
                    width: 70%;
                    text-align: center;
                }
                .link{
                    text-decoration: none;
                    margin-top: 10px;
                    color: $colorSecondary;
                    font-weight: bold;
                }
                
            }
            &.active .item{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 175px;
                height: 175px;
                color: white;
                border-radius: 50%;
                transition: all .3s ease-in-out;
                border: 3px solid $colorSecondary;
                cursor:default;
                background-image: url('./sections/aboutme-fullblue.png');
                background-size: cover;
                .date-pre{
                    display:none;
                }
                .link,.title, .date{
                    height: initial;
                    opacity: 1;
                    transition: all .1s ease-out;
                    transition-delay: .2s;
                }
                .link{
                    cursor: pointer!important;
                    pointer-events: initial;
                }
                
            }
        }
        
    }

    ._modal-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 60vh;
        align-items: center;
        .title{
            text-align: center;
        }
        .buttons-container{
            width: 100%;
            display: flex;
            justify-content: center;
            .button{
                margin: auto;
            }
        }
    }
}



@media (max-width: 576px){
    section.webinar{
        padding: 0 1%;
        .container{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;    
        }
    }
}