footer{
    display: flex;
    padding: 40px 0;
    flex-direction: column;
    align-items: center;
    background-color: $colorPrincipal;
    z-index: 1000;
    position:relative;
    
    .content{
        display: flex;
        justify-content: space-around;
        width: 100%;
        max-width: 1200px;
        
        .social{
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: white;
            height: 100%;
            .logo{
                font-size: xx-large;
                margin-bottom: 20px;
            }
            a{
                color: white;
                text-decoration: none;
                margin-bottom: 15px;
                transition: all .3s ease-in-out;
                &:hover{
                    color: yellow;
                    transform: translateX(3px);
                    i{
                        color: yellow;
                    }
                }
                i{
                    color: white;
                    width: 20px;
                }
            }
            i{
                margin: 0 15px;
            }
        }
        .twContainer{
            margin: 0 40px;
        }

    }
    .copyright{
        color: white;
        font-family: $fontPrincipal;
        font-weight: bold;
        a,a:active,a:focus{
            color: white;
        }
    }
    
}

@media (max-width: 768px){
    footer{
        align-items: center;
        .content{
            flex-wrap: wrap;
            align-items: center;
            .social{
                width: 30%;
            }
            .twContainer{
                margin: 20px 0;
            }
        }
        
    }
}


@media (max-width: 576px){
    footer{
        .content{
            flex-wrap: wrap;
            align-items: center;
            form{
                margin: 40px 0;
                width: 80%;
                order: 3;
            }
            .social{
                width: 100%;
                margin: 40px 0;
                align-items: center;
            }
            
        }
        
    }
}