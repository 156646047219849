section.panel{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    .side{
        position: fixed;
        width: 200px;
        height: 100%;
        display: flex;
        padding: 40px;
        box-shadow: 1px 0 30px silver;
        background-color: $colorPrincipal;
        ul{
            display: flex;
            flex-direction: column;
            padding: 0;
            li{
                margin-bottom: 30px;
                list-style: none;
                a{
                    font-family: $fontPrincipal;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-weight: bold;
                    color: white;
                    cursor: pointer;
                }
            }
            hr{
                margin-bottom: 30px;
                opacity: .3;
            }            
        }
    }
    .content{
        width: calc(100% - 200px);
        display: flex;
        height: 100%;
        padding-top: 80px;
        margin-left: 200px;
        .menu{
            height: 95%;
            width: 100%;
            .footer{
                width: 85%;
                position: fixed;
                display: flex;
                justify-content: center;
            }
            .body{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                padding: 30px;
                margin-top: 60px;
                justify-content: center;  
                .field{
                    margin: 10px;
                    width: 320px;
                    height: 250px;
                    box-shadow: 0 0 30px silver;
                    border-radius: 15px;
                    img{
                        max-height: 162px;
                        object-fit: cover;
                    }
                }
                .post-field{
                    margin: 10px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px;
                    box-shadow: 0 0 20px silver;
                    border-radius: 10px;
                }
            }
            .empty-container{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .footer{
                bottom: 0;
                padding: 30px 0;
            }
        }
    }
    .nav{
        position: fixed;
    }
}

.close-btn{
    cursor: pointer;
}

.moment-modal{
    font-size: small;
    color: gray;
}


@media (max-width: 768px){
    section.panel{
        flex-direction: column;
        .side{
            padding: 0;
            width: 100vw;
            height: 150px;
            display: flex;
            align-items: flex-end;
            ul{
                width: 100%;
                flex-direction: row;
                justify-content: space-around;
                li{
                    margin:0;
                }
            }
        }
        .content{
            margin-left:0;
            margin-top: 0px;
            width: 100%;
            .menu{
                .footer{
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }
}