*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    background-color: whitesmoke;
    overflow-x: hidden;
}

$fontPrincipal: 'Lato', sans-serif;
$fontSecondary: 'Merriweather Sans', sans-serif;
$colorPrincipal: #1B324D;
$colorSecondary: #ffc107;

@mixin title($width) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    h3{
        font-size: 45px;
        font-weight: bold;
        font-family: $fontPrincipal;
        color: $colorPrincipal;
        z-index: 2;
    }   
    //.subs{
    //    width: $width;
    //    height: 20px;
    //    background-color: $colorSecondary;
    //    border-radius: 15px;
    //    transform: translateY(-120%);
    //}
    @media(max-width:576px){
        h3{
            font-size: 36px;
        }
        //.subs{
        //    width: math.div($width, 36px) * 100%;
        //}        
    }
}



@import 'views/commons/navbar';    
@import 'views/commons/footer';  
@import 'views/commons/contact'; 
@import 'views/commons/newsletter'; 

@import 'views/Home/principal';
@import 'views/Home/talent';
@import 'views/Home/articles';
@import 'views/Home/media';
@import 'views/Home/aboutme';
@import 'views/Home/book';
@import 'views/Home/webinar';

@import 'views/Panel/layout';
@import 'views/Panel/commons/navbar';

@import 'views/Articles/articles';
@import 'views/Login/login';