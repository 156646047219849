.login-section{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: white;
    .box{
        background-color: $colorPrincipal;
        height: 60%;
        display: flex;
        font-family: $fontPrincipal;
        box-shadow: 0 0 40px gray;
        border-radius: 15px;
        .container{
            padding: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .title{
                font-weight: bold;
                text-transform: uppercase;
                color:white;
                margin-bottom: 30px;
    
            }
            .field{
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 10px auto;
                label{
                    margin-bottom: 5px;
                    color: white;
                }
                input{
                    border:none;
                    padding: 5px;
                    border-radius: 5px;
                    color: black;
                    &:active,&:focus{
                        border-style: none;
                        border: none;
                        outline: none;
                    }
                }
            }
            button{
                margin-top: 30px;
            }

        }
        img{
            border-bottom-right-radius: 15px;
            border-top-right-radius: 15px;
        }
    }
    .back-link{
        text-decoration: none;
        position: fixed;
        left: 10px;
        top: 10px;
        font-family: $fontPrincipal;
        font-weight: bold;
        color: $colorPrincipal;
        font-size: x-large  ;
        display: flex;
        align-items: center;
        .fal{
            color: $colorPrincipal;
            margin-right: 10px;
        }
        &:active{
            color: yellow;
        }
    }
}