.nav{
    width: 85%;
    position:absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items:center;
    padding: 10px 30px;
    height: 80px;
    z-index: 10;
}