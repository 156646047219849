form.form-contact{
    display: flex;
    flex-direction: column;
    margin: 0 40px;
    width: 20%;
    .field{
        width: 100%;
        margin: 10px 0 ;
        input, textarea{
            width: 100%;
            padding: 5px;
            color: rgb(58, 58, 58);
            font-size: small;
            text-transform: uppercase;
        }
        input, textarea{
            border-radius: 5px;
        }
        span{
            color: white;
        }
        textarea{
            resize: none;
            height: 90px;
        }
    }
}

@media (max-width: 768px){
    form{
        margin: 20px 0;
        width: 40%;
        order: 3;
    }
}

@media (max-width: 576px){
    form{
        margin: 40px 0;
        width: 80%;
        order: 3;
    }
}
