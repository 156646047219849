section.principal{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        object-fit:cover;
    }
    .bgk{
        position: relative;
        z-index: 2;
        width: 35%;
        height: 100vh;
        right: 0;
        top: 0;
        background-color: rgba($color: $colorPrincipal, $alpha: .9);
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        .nav-links{
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items:center;
            a{
                cursor: pointer;
                font-family: $fontPrincipal;
                text-decoration: none;
                text-transform: uppercase;
                color: white;
                margin: 10px 0;
                font-weight: bold;
                font-size: large;
                transition: all .3s ease-in-out;
                height: 30px;
                
                &:hover{
                    color: $colorSecondary;
                    font-size: 20px;
                    height: 30px;
                }
            }
        }
        .text{
            width: 100%;
            color: white;
            font-size: xx-large;
            font-family: $fontPrincipal;
            text-align: center;
            h1{
                font-size: 60px;
            }
            h3{
                font-size: x-large;
            }
            .social{
                width: 100%;
                display: flex;
                justify-content: center;
                a{
                    color: white;
                    width: 40px;
                    height: 40px;
                    transition: all .3s ease-in-out;
                    &:hover{
                        color: $colorSecondary;
                    }
                    i{
                        font-size: large;
                        margin: 10px;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px){
    section.principal{
        flex-direction: column;
        img{
            object-position: 10%;
            top: 0;
        }
        .bgk{
            width: 100%;
            height: 25%;
            .nav-links{
                flex-direction: row;
                justify-content: center;
                flex-wrap: wrap;
                .link{
                    margin: 10px;
                }
            }
        }
    }
}


@media (max-width: 576px){
    section.principal{
        img{
            object-position: 20%;
        }
        .bgk{
            width: 100%;
            min-height: 35%;
            .text{
                h1{
                    font-size: 50px;
                }
            }
        }
    }
}