section.article{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 60px 0;
    .title{
        @include title(155px);
    }
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        
        .writes{
            width: 60%;
            padding-top: 10px;
            .pretitle{
                font-size: xx-large;
                text-align: center;
                text-transform: capitalize;
                font-family: $fontPrincipal;
                font-weight: bold;
            }
            .box{
                margin: 30px;
                display: flex;
                flex-direction: column;
                .title{
                    padding-left: 10px;
                    border-left: 4px solid $colorPrincipal;
                    font-family: $fontPrincipal;
                    font-weight: bold;
                    font-size: x-large;
                    color: gray;
                }
                .date{
                    font-family: $fontSecondary;
                    font-size: small;
                    margin: 15px 0;
                }
                .text{
                    font-family: $fontSecondary;
                }
                button.more{
                    align-self: center;
                    margin: 20px 0; 
                    border: none;
                    background-color: $colorPrincipal;
                    color: white;
                    padding: 5px 10px;
                    border-radius: 6px;
                }
                .share{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    margin: 20px 0;
                    i{
                        margin: 0 10px;
                    }
                }
            }
        }
        
    }
    
}

@media (max-width: 768px){
    section.article{
        .container{
            flex-direction: column;
            .writes{
                width: 100%;
            }
            
        }
    }
}