section.book{
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
    .img-container{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        p{
            width: 50%;
            text-align: left;
            font-family: $fontPrincipal;
        }
        img{
            width: 40%;
        }
        .title{
            @include title(130px);
        }
        
        .buttons-container{
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 30px;
            .button-field{
                display: flex;
                flex-direction: column;
                margin: 0 20px;
            }
            label{
                font-size: xx-small;
                text-align: right;
                color: gray;
                margin-top: 5px;
            }
        }
    }
    .text{
        background-image: url('./sections/book-bgk.png');
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: white;
        height: 100%;
        width: 50%;
        padding: 80px;
        font-family:  $fontSecondary;
        br{
            margin: 10px 0;
        }
        .epilogo{
            font-family:  $fontSecondary;
        }
        .title{
            margin-top: 30px;
            text-align: center;
            color: $colorSecondary;
        }
        .item{
            text-align: center;
        }
    }
}

@media (max-width: 768px){
    section.book{
        padding-top: 150px;
        height: auto;
        .img-container{
            width: 100%;
            
        }
        .text{
            width: 100%;
            padding : 60px;
        }
    }
}



@media (max-width: 576px){
    section.book{
        .img-container{
            
            img{
                width: 80%;
            }
            .buttons-container{
                align-items: center;
                flex-direction: column;
                p.title{
                    width: 90%!important;
                }
                .button-field{
                    display: flex;
                    flex-direction: column;
                    margin: 20px;
                }
            }
        }
        .text{
            padding: 30px;
            .epilogo{
                max-height: 50vh;
                overflow-y: scroll;
                text-align: center;
            }
        }
    }
}