section.talent{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    .title{
        @include title(380px);
    }
    .content{
        display: flex;
        background-size: cover  ;
        background-image: url('../Home/sections/talent.png');
        background-position-y: center;
        flex-wrap: wrap;
        .img-container{
            width: 50%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            padding: 30px;
            img{
                width: 30%;
            }
        }
        .text{
            border-radius: 10px;
            color: white;
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 50px;
            flex-grow: 3;
            h3{
                line-height: 1.6;
                font-family: $fontSecondary;
                font-size: medium;
            }
            a{
                margin-top: 20px;
            }
        }
        .container-button{
            width: 50vw;
            display: flex;
            justify-content: center;
            margin: 0 0 40px 50%;
        }
    }
}

@media (max-width: 1000px){
    section.talent{
        .content{
            .text{
                margin: 0 30px;
                width: 100%;
                padding:30px;
                h3{
                    text-align: center;
                }
            }
            .container-button{
                width: 100vw!important;
                margin: 20px 0;
            }
        }
    }
}

@media (max-width: 768px){
    section.talent{
        .content{
            flex-direction: column;
            .img-container{
                width: 100%;
                justify-content: center;
                align-items: center;
                padding: 5px;
                img{
                    width: 50%;
                }
            }
            .text{
                margin: 0 30px;
                width: 100%;
                h3{
                    text-align: center;
                }
            }
            .container-button{
                width: 100%;
                margin: 20px 0;
            }
        }
    }
}

@media (max-width: 576px){
    section.talent{
        .content{
            padding: 20px 0;;
            .text{
                max-height: 40vh;
                overflow-y: scroll;
                justify-content: flex-start;
                padding: 0;
                margin: 0;
                h3{
                    margin: 0;
                    width: 80%;
                }
            }
            
        }
    }
}