nav{
    background-color: $colorPrincipal;
    border-bottom: 2px solid $colorPrincipal;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.288);
    height: 60px;
    width: 100vw;
    z-index: 12;
    position:fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 100px;
    text-align: left;
    .btn{
        position: absolute;
        left: 10%;
        display: none;
        border: none;
        background-color: transparent;
        i{
            background-color: transparent;
            color: white;
        }

    }
    ul{
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9;
        padding: 0!important;
        margin: 0;
        li{
            font-size: small;
            cursor: pointer;
            text-transform: uppercase;
            font-family: 'PT Sans Narrow', sans-serif;
            color: white;
            list-style: none;
            margin: 0 10px;
            letter-spacing: 1px;
            a{
                text-decoration: none;
                color: white;
                padding: 0;
                &:hover{
                    color: $colorSecondary;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    nav{
        ul{
            position: fixed;
            height: 100vh;
            width: 35vw;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            top: 0;
            left: -100vw;
            margin-top: 60px;
            overflow: hidden;
            background-color: #1B324D;
            border-right: 2px solid gray;
            box-shadow: 3px 1px 3px rgba(0, 0, 0, 0.288);
            transition: all .3s ease-in-out ;
            li{
                padding-top: 5%;
                padding-left: 15%;  
                font-size: 16px;
                margin: 10px 0;;
            }
        }
        .btn{
            display: block;
            cursor: pointer;
            font-size: xx-large;
        }
        &.active ul{
            left:0;
            li{
                width: 100%;
            }
        }
    }
}

@media (max-width: 576px) {
    nav{
        ul{
            width: 50vw;
            li{
                font-size: 16px;
                margin: 5px 0;;
            }
        }
    }
}
